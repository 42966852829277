import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import breadcrumbComp from "../../common/breadcrumb-comp";
import shippingService from "./shipping-service";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      oldWaybillVal: "",
      validOldWaybillVal: false,
      isFormValid: false,
      max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Max Length must be 50 characters or less"],
      waybillDetails: [],
      validNewWaybillVal: false,
      newWaybillVal: "",
    };
  },
  async created() {
    this.primaryName = this.$cookies.get("primaryName");
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {},
  methods: {
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.oldWaybillVal = "";
      this.validOldWaybillVal = false;
      this.isFormValid = false;
      this.waybillDetails = [];
      this.validNewWaybillVal = false;
      this.newWaybillVal = "";
      this.$refs.waybillForm.resetValidation();
      this.$refs.newWaybillForm.resetValidation();
    },
    //Validate button click event
    async validateWaybill() {
      if (this.$refs.waybillForm.validate()) {
        let obj = {
          pass: 1,
          cur_waybill: this.oldWaybillVal,
          new_waybill: "",
          user_id: parseInt(this.userId),
        };
        let showMessage = false;
        let response = await shippingService.shippingWaybillChange("post", obj, showMessage);
        if (response != "NA") {
          this.validOldWaybillVal = true;
          this.waybillDetails = response;
          this.newWaybillVal = "";
          this.$refs.newWaybillForm.resetValidation();
        }
      }
    },
    //New waybill submit button click event
    async newWaybillSubmit() {
      if (this.$refs.newWaybillForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let obj = {
          pass: 2,
          cur_waybill: this.oldWaybillVal,
          new_waybill: this.newWaybillVal,
          user_id: parseInt(this.userId),
        };
        this.axios
          .post("sh/shipping_waybill_change", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.resetFunction();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
  },
  components: {
    breadcrumbComp,
  },
};
